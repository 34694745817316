// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

// .c-sidebar-nav-item:hover{
//     background-color: #074b7b !important;
// }
// .c-sidebar-nav-link:hover{
//     background-color: #074b7b !important;
// }
// .c-show{
//     background-color: #0C71B9 !important;
// }
// .c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
//     color: #fff;
//     background-color: #074b7b !important;
// }
// .c-sidebar-nav {
//     position: relative;
//     display: flex;
//     flex: 1 1;
//     flex-direction: column;
//     padding: 0;
//     margin-bottom: 0;
//     overflow-x: hidden;
//     overflow-y: auto;
//     list-style: none;
//     background-color: #0C71B9 !important;
// }
// .c-sidebar .c-sidebar-minimizer {
//     background-color: #0C71B9;
// }